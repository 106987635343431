
import Vue, { PropType } from 'vue'
import LoginButton from '@/bookingbuddy-shop/components/account/LoginButton.vue'

interface LoginMethod {
  label: string
  idpUuid: string | null
}

export interface CommunityAccessError {
  status: string
  code: string
  title: string
  detail: string
  meta: {
    communities: {
      id: string
      slug: string | null
      name: string | null
      allow_customer_requests: boolean
      allow_organization_requests: boolean
      idp_uuid: string | null
    }[]
  }
}

export default Vue.extend({
  name: 'CommunityAccessError',
  components: { LoginButton },
  props: {
    error: {
      type: Object as PropType<CommunityAccessError>,
      required: true,
    },
  },
  data() {
    return {
      loggingIn: false,
    }
  },
  computed: {
    redirectPath(): string {
      return this.$config.baseUrl + this.$route.path
    },
    idpUuid(): string | null {
      if (this.error.meta.communities.length === 1) {
        return this.error.meta.communities[0].idp_uuid
      }
      return null
    },
    loginMethods(): LoginMethod[] {
      const communityData = this.error.meta.communities

      const communitiesWithIdp = communityData.filter(
        (community) => community.idp_uuid !== null
      )
      const hasCommunityWithoutIdp =
        communitiesWithIdp.length < communityData.length ||
        communityData.length === 0

      const methods: LoginMethod[] = communitiesWithIdp.map((community) => ({
        label: this.$t('common.actions.login') + ` (${community.name})`,
        idpUuid: community.idp_uuid,
      }))

      if (hasCommunityWithoutIdp) {
        methods.push({
          label: this.$t('common.actions.login') as string,
          idpUuid: null,
        })
      }
      return methods
    },
  },
  methods: {
    async handleLogin() {
      this.loggingIn = false
      await this.$router.push(this.$route.path)
    },
  },
})
