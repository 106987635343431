
import { defineComponent, PropType } from 'vue'
import LoadingIcon from '@/shared/components/partials/LoadingIcon.vue'
import { TranslateResult } from 'vue-i18n'
import {
  createRequestAuthorizationCodeData,
  exchangeCodeForAccessToken,
} from '@/shared/utils/authHelpers'

export default defineComponent({
  name: 'LoginButton',
  components: { LoadingIcon },
  props: {
    redirectUrl: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    buttonClasses: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({
        'eb-btn': true,
        primary: true,
      }),
    },
    buttonLabel: {
      type: String as PropType<string | null>,
      default: null,
    },
    idpUuid: {
      type: String as PropType<string | null>,
      default: null,
    },
    buttonIcon: {
      type: Array as PropType<string[]>,
      default: () => ['far', 'arrow-right'],
    },
  },
  data() {
    return {
      loginWindowInterval: null as number | null,
      loginWindowPollingInterval: null as number | null,
      windowLoopCount: 0,
      loading: false,
    }
  },
  computed: {
    finalButtonLabel(): string | TranslateResult {
      return this.buttonLabel ?? this.$i18n.t('common.actions.login')
    },
    baseUrl(): string {
      return this.$config.shopBaseUrl
    },
    entityId(): string {
      return this.$config.entityId
    },
    finalIdpUuid(): string | null {
      return this.idpUuid ?? this.$config.idpUuid
    },
    target(): string | null {
      // explicitly set target
      if (this.redirectUrl) {
        return this.redirectUrl
      } else {
        // redirect to current route
        return this.baseUrl + this.$route.fullPath
      }
    },
  },
  methods: {
    async login() {
      this.$emit('login-start')
      if (!this.$ux.isEmbedded) {
        await this.$router.push(
          this.localePath({
            path: '/login',
            query: {
              target: this.target,
              entityId: this.entityId,
              idpUuid: this.finalIdpUuid ?? undefined,
            },
          })
        )
      } else {
        await this.loginEmbedded()
      }
    },
    async loginEmbedded() {
      this.loading = true
      // we need to open the window as the first action after the user clicked the button
      // otherwise the browser will block the popup
      const loginWindow = window.open(
        '',
        'popup',
        'popup=true,width=500,height=800'
      )
      if (!loginWindow) {
        return
      }

      try {
        // get authorize url, state and code verifier
        let authorizationData = await this.prepareAuthorizationCode()

        // get the code, if the login flow will redirect to the callback url
        // the code will be provided in the url
        let authorizationCode = await this.getAuthorizationCode(
          loginWindow,
          authorizationData.url
        )

        // with the code, we can fetch the access token
        let tokenData = await this.getAccessToken(
          authorizationCode,
          authorizationData.codeVerifier
        )

        this.$authService.updateTokens({
          accessToken: tokenData.accessToken,
          refreshToken: tokenData.refreshToken,
          expiresIn: Number(tokenData.expiresAt),
          saveAccessToken: true,
        })
        await this.$authService.requestCustomerAccount(false)
        this.$emit('login-success')
      } catch (e) {
        this.$emit('login-error')
        console.log(e)
        return
      } finally {
        this.loading = false
      }
    },
    async getAuthorizationCode(
      loginWindow: Window,
      url: string
    ): Promise<string> {
      this.windowLoopCount = 6000
      loginWindow.location.href = url
      return new Promise((resolve, reject) => {
        // check new opened window once per second
        this.loginWindowInterval = window.setInterval(() => {
          if (loginWindow?.closed) {
            this.clearInterval()
            return reject('Window closed.')
          }
          // handle timeout
          if (this.windowLoopCount-- < 0) {
            loginWindow?.close()
            this.clearInterval()
            return reject('Authorization timeout')
          }
          // check window url to get authorization code
          else if (loginWindow) {
            let href: string | null = null
            try {
              href = loginWindow.location.href
            } catch (e) {
              console.log(e)
            }
            if (href !== null && href.match('code')) {
              // authorization code provided
              let authorizationCode = String(this.getQueryString('code', href))
              // clean up state
              loginWindow.close()
              this.clearInterval()
              return resolve(authorizationCode)
            }
          }
        }, 1000)
      })
    },
    async prepareAuthorizationCode() {
      return await createRequestAuthorizationCodeData({
        clientId: this.$config.cognitorClientId,
        scope: '',
        endpoint: this.$config.cognitorBaseUri + '/oauth/authorize',
        redirectUri: this.baseUrl + '/login/callback',
        params: {
          idpUuid: this.finalIdpUuid ?? undefined,
        },
        loginStatePrefix: this.$embedService ? 'embedded-' : '',
      })
    },
    getQueryString(field: string, url: string) {
      const windowLocationUrl = url
      const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
      const string = reg.exec(windowLocationUrl)
      return string ? string[1] : null
    },
    async getAccessToken(authorizationCode: string, codeVerifier: string) {
      return exchangeCodeForAccessToken({
        axiosClient: this.$clients.cognitorApi!,
        clientId: this.$config.cognitorClientId,
        endpoint: this.$config.cognitorBaseUri + '/oauth/token',
        redirectUri: this.baseUrl + '/login/callback',
        code: authorizationCode,
        codeVerifier: codeVerifier,
      })
    },
    clearInterval() {
      if (this.loginWindowInterval) {
        window.clearInterval(this.loginWindowInterval)
        this.loginWindowInterval = null
      }
      if (this.loginWindowPollingInterval) {
        window.clearInterval(this.loginWindowPollingInterval)
        this.loginWindowPollingInterval = null
      }
    },
  },
})
