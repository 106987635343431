import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Item } from './Item'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Payment } from '@/shared/jsonapi-orm/payments/Payment'
import { PaymentRefund } from '@/shared/jsonapi-orm/payments/PaymentRefund'
import { SentEmail } from '@/shared/jsonapi-orm/common/SentEmail'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { Customer } from '@/shared/types/bookings/CommunityBookings'

export enum InvoiceStatusType {
  DRAFT = 'draft',
  SENT = 'sent',
  PAID = 'paid',
  OVERDUE = 'overdue',
  VOID = 'void',
  WRITE_OFF = 'write_off',
  REFUNDED = 'refunded',
}

export class Invoice extends ApiResource {
  static jsonApiType = 'invoices'
  @Attr() number: string | null
  @Attr() formattedNumber: string | null
  @Attr() currency: string
  @Attr() isCredit: boolean
  @Attr() isCancellation: boolean
  @Attr(false) locked: boolean

  @Attr() recipient: string | null
  @Attr() invoicingParty: string

  @Attr(0) total: number
  @Attr(0) netAmount: number
  @Attr(0) net_amount: number
  @Attr(0) taxAmount: number
  @Attr(0) tax_amount: number
  @Attr(InvoiceStatusType.DRAFT) status: InvoiceStatusType
  @Attr() accessToken?: string
  @Attr() dueDate: string | null
  @Attr() issuedAt: string | null
  @Attr() createdAt: string
  @Attr(false) hasPayment: boolean
  @Attr() showTransferNote: boolean
  @Attr(false) isQuote: boolean
  @Attr() allowOnlinePayment: boolean
  @Attr() paymentReminders: boolean
  @Attr(false) autoLock: boolean
  @Attr(false) isProcessing: boolean
  @Attr() vatNumber: string | null
  @Attr() notes: string | null
  @Attr() countryCode: string
  @Attr() region: string
  @Attr() fields:
    | null
    | { id: string; type: string; label: string; value: string | null }[]
  @Attr(true) calculateFromNet: boolean
  @Meta() downloadUrl: string
  @Meta() xmlDownloadUrl: string
  @Meta() quoteDownloadUrl: string
  @Meta() referenceType: string
  @Meta() hasXml: boolean

  get calculatedTotal(): number {
    if (this.items) {
      return this.items.reduce((sum, item) => {
        return sum + item.grossAmount
      }, 0)
    } else {
      return this.total
    }
  }

  @HasMany() items?: Item[]
  @HasMany() payments?: Payment[]
  @HasOne() refund?: PaymentRefund
  @HasOne() tmpOrder?: Order
  @HasMany() sentEmails?: SentEmail[]
  @BelongsTo() reference?: ApiResource
  @BelongsTo() customer?: Customer | null
  @BelongsTo() owner?: Organization | null
  @HasOne() payment?: Payment
  @HasOne() cancellationInvoice?: Invoice
  @HasOne() cancelledInvoice?: Invoice

  /**
   * Send invoice.
   */
  send = async (query?: Record<string, any>): Promise<boolean> => {
    try {
      const response = await this.api()
        .query(query ?? {})
        .request('send')
      Invoice.resourceFromResponse(response.data, this.apiService)
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  cancel = async (): Promise<boolean> => {
    try {
      const response = await this.api()
        .with(['cancellation_invoice', 'cancelled_invoice'])
        .request('cancel')
      Invoice.resourceFromResponse(response.data, this.apiService)
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }
  setPaid = async (sendNotification = true): Promise<boolean> => {
    try {
      const response = await this.api()
        .query({ sendNotification })
        .request('set-paid')
      Invoice.resourceFromResponse(
        response.data,
        this.apiService
      ).data.$withoutReactivity()
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  addNewItem(taxRate = 0) {
    const item = new Item(this.apiService)
    item.currency = this.currency
    item.taxRate = taxRate
    item.orderIndex = this.items ? this.items.length : 0
    item.title = 'New Item'
    this.items = [...(this.items ?? []), item]
  }
}
